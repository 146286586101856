<template>
	<div>

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-container>
      <b-card  title="Import customers" class="card-icon mb-30 p-16">
        <b-row>
          <b-col lg="12" md="12" sm="12">

            <div class="row">

              <div class="col-md-3">

              </div>

              <div class="col-md-6">

                <input type="file" ref="customerFile" @change="handleFileUpload" />


                <div class="row" >
                  <div class="col-md-12">
                    <hr/>
                  </div>
                </div>

                <div class="row" >
                  <div class="col-md-12">
                    <button @click="saveImportedFile" class="btn btn-danger float-right" style="color:#fff;" > Submit </button>
                  </div>
                </div>



              </div>

              <div class="col-md-3">

              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-container>




	</div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
	import vueFilePond from 'vue-filepond';
	import 'filepond/dist/filepond.min.css';
	import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
	import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
	import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css'

	const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

	export default {
		name: 'customers',
		data () {
			return {
        isLoading: false,
        fullPage: true,
				myFiles:[],
              fileUpload:"",
			}

		},
		computed: {

		},
		mounted (){
		},
		components: {
      Loading
		},

		methods: {
      ...mapActions(["importCustomers"]),
              handleFileUpload(){
                this.fileUpload = this.$refs.customerFile.files[0];
              },
			saveImportedFile(){

              if(this.fileUpload){
                this.isLoading = true;

                let formData = new FormData();
                formData.append('excelFile', this.fileUpload);

                this.importCustomers(formData).then(() => {
                  self.isLoading = false;

                  self.$bvToast.toast("Customers imported successfully.", {
                    title: 'Success',
                    variant: 'success',
                    autoHideDelay: 5000,
                    appendToast: false
                  });

                  this.$router.push("/apps/customers");

                  //self.getOutletData();

                })
                        .catch(error => {

                          console.log(error)

                          self.isLoading = false;

                          self.$bvToast.toast(error.message, {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000,
                            appendToast: false
                          });

                        })

              }


			},
			handleFileSelected(files){

				let file_exists = [];

				this.myFiles = [];

				let self = this;

				files.forEach(function (file) {

					if(!file_exists.includes(file.file.name)){
						self.myFiles.push(file.file);
						file_exists.push(file.file.name)
					}
				})

			}
		}
	}
</script>
